import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { AuthGuard } from './shared/guards';
import { Role } from "./models";

const routes: Routes = [
  //Front End Pages
  // Home
  {
    path: '',
    loadChildren: () =>
      import('./modules/frontend/home-page/home-page.module').then(
        mod => mod.HomePageModule,
      ),
  },
  {
    path: 'mobile/apps',
    loadChildren: () =>
      import('./modules/frontend/pages/download-app/download-app.module').then(
        mod => mod.DownloadAppModule,
      ),
  },
  {
    path: 'about-freshfeelz',
    loadChildren: () =>
      import('./modules/frontend/pages/about-us/about-us.module').then(
        mod => mod.AboutUsModule,
      ),
  },
  {
    path: 'contact-us',
    loadChildren: () =>
      import('./modules/frontend/pages/contact-us/contact-us.module').then(
        mod => mod.ContactUsModule,
      ),
  },

  /// Public page

  {
    path: 'careers-at-freshfeelz',
    loadChildren: () =>
      import('./modules/frontend/pages/careers/careers.module').then(
        mod => mod.CareersModule,
      )
  },

  // Privacy
  {
    path: 'privacy-policy',
    loadChildren: () =>
      import('./modules/frontend/pages/privacy/privacy.module').then(
        mod => mod.PrivacyModule,
      )
  },
  // Terms
  {
    path: 'terms-conditions',
    loadChildren: () =>
      import('./modules/frontend/pages/term-conditions/term-conditions.module').then(
        mod => mod.TermConditionsModule,
      )
  },
  // ai
  {
    path: 'account-information',
    loadChildren: () =>
      import('./modules/frontend/pages/account-information/account-information.module').then(
        mod => mod.AccountInformationModule,
      )
  },

  // Customer Support
  {
    path: 'customer-support',
    loadChildren: () =>
      import('./modules/frontend/pages/customer-support/customer-support.module').then(
        mod => mod.CustomerSupportModule,
      )
  },
  // Become Ambassador
  {
    path: 'become-an-ambassador',
    loadChildren: () =>
      import('./modules/frontend/pages/become-ambassador/become-ambassador.module').then(
        mod => mod.BecomeAmbassadorModule,
      ),
  },
  // Frequently Asked Questions
  {
    path: 'frequently-asked-questions',
    loadChildren: () =>
      import('./modules/frontend/pages/frequently-asked-questions/frequently-asked-questions.module').then(
        mod => mod.FrequentlyAskedQuestionsModule,
      ),
  },

  // FreshFeelz Rewards
  {
    path: 'rewards',
    loadChildren: () =>
      import('./modules/frontend/pages/ff-rewards/ff-rewards.module').then(
        mod => mod.FfRewardsModule,
      ),
  },
  // partner
  {
    path: 'partner',
    loadChildren: () =>
      import('./modules/frontend/partner/home-vendor-page/home-vendor.module').then(
        mod => mod.HomeVendorModule,
      ),
  },
  // Auth partner
  {
    path: 'partner/signup',
    loadChildren: () =>
      import('./modules/auth/signup-as-vendor/signup-as-vendor.module').then(
        mod => mod.SignupAsVendorModule
      )
  },
  {
    path: 'partner/registration',
    loadChildren: () =>
      import('./modules/frontend/partner/our-interest/our-interest.module').then(
        mod => mod.OurInterestModule,
      )
  },
  {
    path: 'partner/enquiry',
    loadChildren: () =>
      import('./modules/frontend/partner/enquiry/enquiry.module').then(
        mod => mod.EnquiryModule,
      ),
  },

  // Customer Signup
  {
    path: 'signup',
    loadChildren: () =>
      import('./modules/auth/register/register.module').then(
        mod => mod.RegisterModule
      )
  },
  // Login
  {
    path: 'login',
    loadChildren: () =>
      import('./modules/auth/login/login.module').then(
        mod => mod.AuthModule
      )
  },
  // Forget password
  {
    path: 'forget-password',
    loadChildren: () =>
      import('./modules/auth/forgetpassword/forgetpassword.module').then(
        mod => mod.AuthModule
      )
  },
  // Reset password
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/auth/auth.module').then(
        mod => mod.AuthModule
      )
  },
  // Shop
  {
    path: 'shop',
    loadChildren: () =>
      import('./modules/frontend/shop/shop.module').then(
        mod => mod.ShopModule,
      ),
  },
  // Search
  {
    path: 'search',
    loadChildren: () =>
      import('./modules/frontend/search/search.module').then(
        mod => mod.SearchModule,
      ),
  },
  // Deals
  {
    path: 'deals',
    loadChildren: () =>
      import('./modules/frontend/deals/deal.module').then(
        mod => mod.DealModule,
      ),
  },

  // Categories
  // {
  //   path: 'popular-categories',
  //   loadChildren: () =>
  //     import('./modules/frontend/popular-categoies/popular-categoies.module').then(
  //       mod => mod.PopularCategoiesModule,
  //     ),
  // },
  // Services
  {
    path: 'services',
    loadChildren: () =>
      import('./modules/frontend/services/services.module').then(
        mod => mod.ServicesModule,
      ),
  },
  // Services
  {
    path: 'cart',
    loadChildren: () =>
      import('./modules/frontend/cart/cart.module').then(
        mod => mod.CartModule,
      ),
  },
  // Venues or partners
  {
    path: 'partners',
    loadChildren: () =>
      import('./modules/frontend/venues/venues.module').then(
        mod => mod.VenuesModule,
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./modules/frontend/venues/venues.module').then(
        mod => mod.VenuesModule,
      ),
  },
  // customer dashboard
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./modules/frontend/customer/dashboard/dashboard.module').then(
        mod => mod.DashboardModule,
      ),
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.Ambassaor] }
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./modules/frontend/customer/profile/profile.module').then(
        mod => mod.ProfileModule,
      ),
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.Ambassaor] }
  },
  {
    path: 'my-deals',
    loadChildren: () =>
      import('./modules/frontend/customer/deals/deals.module').then(
        mod => mod.DealsModule,
      ),
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.Ambassaor] }
  },

  {
    path: 'orders',
    loadChildren: () =>
      import('./modules/frontend/customer/orders/orders.module').then(
        mod => mod.OrdersModule,
      ),
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.Ambassaor] }
  },

  {
    path: 'appointments',
    loadChildren: () =>
      import('./modules/frontend/customer/appointments/appointments.module').then(
        mod => mod.AppointmentsModule,
      ),
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.Ambassaor] }
  },
  {
    path: 'my-favourites',
    loadChildren: () =>
      import('./modules/frontend/customer/my-favourites/my-favourites.module').then(
        mod => mod.MyFavouritesModule,
      ),
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.Ambassaor, Role.MasterAdministrator] }
  },
  {
    path: 'wallet',
    loadChildren: () =>
      import('./modules/frontend/customer/wallet/wallet.module').then(
        mod => mod.WalletModule,
      ),
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.Ambassaor] }
  },
  {
    path: 'ambassador',
    loadChildren: () =>
      import('./modules/frontend/ambassador/ambassador.module').then(
        mod => mod.AmbassadorModule,
      ),
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.Ambassaor] }
  },
  {
    path: 'activities',
    loadChildren: () =>
      import('./modules/frontend/customer/customer-activity/customer-activity.module').then(
        mod => mod.CustomerActivityModule,
      ),
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.Ambassaor] }
  },
  // Settings
  {
    path: 'settings',
    loadChildren: () =>
      import('./modules/frontend/settings/settings.module').then(
        mod => mod.SettingsModule,
      ),
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.Ambassaor] }
  },
  // Pricing
  {
    path: 'pricing',
    loadChildren: () =>
      import('./modules/frontend/pages/pricing/pricing.module').then(
        mod => mod.PricingModule,
      ),
  },
  // our partners
  {
    path: 'our-partners',
    loadChildren: () =>
      import('./modules/frontend/our-partners/our-partners.module').then(
        mod => mod.OurPartnersModule,
      ),
  },
  // ===========================
  // Dashboard pages
  // User Dashbaord
  {
    path: 'user1',
    loadChildren: () =>
      import('./modules/dashboard/user-dashboard/user-dashboard.module').then(
        mod => mod.UserDashboardModule,
      ),
    canActivate: [AuthGuard],
    data: { roles: [Role.User] }
  },

  //staff admin
  {
    path: 'staff',
    loadChildren: () =>
      import('./modules/dashboard/staff-dashboard/staff-dashboard.module').then(
        mod => mod.StaffDashboardModule,
      ),
    canActivate: [AuthGuard],
    data: { roles: [Role.Staff, Role.SuperAdmin, Role.Developer] }
  },
  // vendor Dashbaord
  {
    path: 'vendor',
    loadChildren: () =>
      import('./modules/dashboard/vendor-dashboard/vendor-dashboard.module').then(
        mod => mod.VendorDashboardModule,
      ),
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin, Role.Developer, Role.Vendor] }
  },
  // Super Admin Dashbaord
  {
    path: 'superadmin',
    loadChildren: () =>
      import('./modules/dashboard/superadmin-dashbaord/superadmin-dashboard.module').then(
        mod => mod.SuperAdminDashboardModule,
      ),
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin, Role.MasterAdministrator, Role.Developer, Role.Admin] }
  },
  // Admin Dashbaord
  {
    path: 'admin',
    loadChildren: () =>
      import('./modules/dashboard/admin-dashbaord/admin-dashboard.module').then(
        mod => mod.AdminDashboardModule,
      ),
    canActivate: [AuthGuard],
    data: { roles: [Role.SuperAdmin, Role.MasterAdministrator, Role.Developer, Role.Admin] }
  },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top',onSameUrlNavigation: 'reload' }), NgSelectModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }


// [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],