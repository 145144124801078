export enum Role {
	User = 'End User',
	Ambassaor = 'Ambassador',
	Vendor = 'Vendor',
	Staff = 'Staff',
	Account = 'Accounts',
	SalesPersonnel = 'Sales Personnel',
	VendorAppRoles = 'Vendor App Roles',
	Admin = 'Administrator',
	SuperAdmin = 'superadmin',
	Stakeholder = 'Shareholder',
	Developer = 'Developer',
	MasterAdministrator = 'Master Administrator'
}
