import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  HttpClientModule,
  HttpClientJsonpModule,
  HTTP_INTERCEPTORS
} from '@angular/common/http';

import { NotifierModule } from "angular-notifier";
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { DatePipe } from '@angular/common';
import { SignaturePadModule } from 'ngx-signaturepad';
// social login
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider
} from 'angularx-social-login';
import { PriScrollbarModule } from 'pri-ng-scrollbar';

// services
import { JwtInterceptor, ErrorInterceptor } from "src/app/shared/interceptors";
import { ApiService } from './services';

// C
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    NotifierModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    NgProgressModule,
    NgProgressHttpModule,
    SignaturePadModule,
    SocialLoginModule,
    PriScrollbarModule,
    NgbModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    DatePipe,
    ApiService,
    HttpClientModule,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '130986149903-0dcmhgb1uvhahihp1gqq9ub4m5ej1v47.apps.googleusercontent.com'
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('686971642226754')
          }
        ]
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [
    AppComponent
  ],
})
export class AppModule { }
