import { Role } from './role/role.model';

export class User {
	id: string;
	authdata: string;
	role: Role;
	token: string;
	token_type: string;
	referralCode: string;
}
