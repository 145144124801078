import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { server } from 'src/environments/environment';
import { User } from 'src/app/models';
import { TokenService } from './token.service';
import { ApiService } from 'src/app/api.service';
import { SocialAuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  user: any;
  private loggedIn = new BehaviorSubject<boolean>(this.Token.loggedIn());
  authStatus = this.loggedIn.asObservable();
  public UserSub = new BehaviorSubject<boolean>(this.Token.loggedIn());
  AuthUser = this.loggedIn.asObservable();

  constructor(private http: HttpClient, private Token: TokenService, private ApiService: ApiService, private authService: SocialAuthService,    ) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(username: string, password: string) {
    return this.http.post<any>(`${server.baseUrl}/users/signin`, { username, password })
      .pipe(map(user => {

        // store user details and basic auth credentials in local storage to keep user logged in between page refreshes
        user.authdata = window.btoa(username + ':' + password);
        user.id = user.data.user._id;
        user.referralCode = user.data.user.referralCode;
        user.role = user.data.user.roles[0].title;
        user.token = user.data.access_token;
        user.token_type = user.data.token_type;
        this.setAuthUser(user);
        this.currentUserSubject.next(user);
        return user;
      }));
  }

  sociallogin(obj:any) {
    return this.http.post<any>(`${server.baseUrl}/users/sociallogin`, obj)
      .pipe(map(res => {

        this.user = {
          data: {
            access_token: res.access_token,
            expires_in: res.expires_in,
            id: res.data[0]._id,
            role: res.data[0].roles[0].title,
            token_type: res.token_type,
            user: res.data[0],
          },
          message: res.message,
          success: res.success,
          token: res.access_token
        };


        // store user details and basic auth credentials in local storage to keep user logged in between page refreshes
        //user.authdata = window.btoa(username + ':' + password);
        this.user.id = this.user.data.user._id;
        this.user.referralCode = this.user.data.user.referralCode;
        this.user.role = this.user.data.user.roles[0].title;
        this.user.token = this.user.data.access_token;
        this.user.token_type = this.user.data.token_type;
        this.setAuthUser(this.user);
        this.currentUserSubject.next(this.user);
        return this.user;
      }));
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('uid');
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('permissions');
    this.currentUserSubject.next(null);
  }

  setAuthUser(User: any) {
    this.authService.signOut();
    this.UserSub.next(User);
    localStorage.setItem('uid', User.id);
    localStorage.setItem('role', User.role);
    localStorage.setItem('currentUser', JSON.stringify(User));
    localStorage.setItem('token', User.token);
    localStorage.setItem('permissions', User.permissions);
    this.ApiService.resetHeaders();
    this.currentUserSubject.next(User);
    // this.permissionsService.loadPermissions(User.permissions.data);
    // localStorage.setItem('_per', JSON.stringify(User.permissions.data));
  }
}
