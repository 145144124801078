export const environment = {
  production: true,
  Api: 'https://api.freshfeelz.com.au/api/'  // Live
};

export const server = {
  // Live Backend
  apiUrl: "https://api.freshfeelz.com.au/api",
  api: "api",
  api_prefix: "v1",
  baseUrl: "https://api.freshfeelz.com.au/api",
}

export const storage = {
  S3: "https://freshfeelz.s3-ap-southeast-2.amazonaws.com/" // Live S3 Link
};
export const mapbox = {
  accessToken:
    "pk.eyJ1Ijoic2FsbGFodWRkaW4iLCJhIjoiY2swM2RmeGZoMXRzdTNicHI2bGY3eWFqYSJ9.t_UnTTHOIK5ATin6NVpZnA"
};

export const google = {
  apiKey: 'AIzaSyDXpnF16IZEmYjxv5sWvmUiZFYnbvXAHzU'
};

export const stripe = {
 publicKey: "pk_live_51Hk4iVI3JxdAjE8eZY3CMHlhJxPS49zA1N2zvrZFbJkaxJTwmS7A3xrmLFtkvaLRL4InecusiiptsB9XXabhiTKQ00RqRU1e2K", // live public key
  secretKey: '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'// live secret Key
};