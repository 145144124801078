// import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': '',
    'x-access-token': ''
  });
  options = { headers: this.headers };
  constructor(private http: HttpClient) {
    if (localStorage.getItem('token')) {
      this.headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token'),
        'x-access-token': localStorage.getItem('token')
      });
    } else {
      this.headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });
    }

    this.options = { headers: this.headers };
  }

  resetHeaders(headers = {}) {
    const fixedHeaders = {
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('token'),
      'x-access-token': localStorage.getItem('token')
    }
    if (Object.keys(headers).length) {
      let h = { ...headers, ...fixedHeaders };
      console.info(h)
      this.headers = new HttpHeaders(h);
    } else {
      this.headers = new HttpHeaders(fixedHeaders);
    }

    this.options = { headers: this.headers };
  }

  delete(url) {
    return this.http.delete(`${environment.Api}` + url, this.options);
  }
  patch(url, data) {
    return this.http.patch(`${environment.Api}` + url, data, this.options);
  }
  put(url, data) {
    return this.http.put(`${environment.Api}` + url, data, this.options);
  }
  post(url, data) {
    return this.http.post(`${environment.Api}` + url, data, this.options);
  }
  get(url, params = null, headers = {}): Observable<any> {
    //this.resetHeaders(headers);
    return this.http.get(`${environment.Api}` + url, { params: params, headers: this.headers });
  }

  getUsers(params = null): Observable<any> {
    return this.http.get(`${environment.Api}` + 'users', { params: params, headers: this.headers });
  }
  getRoles(params = null): Observable<any> {
    return this.http.get(`${environment.Api}` + 'roleslist', { params: params, headers: this.headers });
  }
  getPermissions(params = null): Observable<any> {
    return this.http.get(`${environment.Api}` + 'permissionslist', { params: params, headers: this.headers });
  }
  getRolesPermissions(params = null): Observable<any> {
    return this.http.get(`${environment.Api}` + 'rolespermissions', { params: params, headers: this.headers });
  }
  getPermissionsByRole(params = null): Observable<any> {
    return this.http.post(`${environment.Api}` + 'rolespermissions/searchr', params, this.options);
  }
}
